import React, { useState, useRef, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"
import { motion, AnimatePresence } from "framer-motion"

export default function VideoPlayer(props) {
    // State
    const [isPlaying, setIsPlaying] = useState(props.autoPlay)
    const [volume, setVolume] = useState(props.volume ? 1 : 0)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [showControls, setShowControls] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [showBigButton, setShowBigButton] = useState(!props.autoPlay)
    const [isButtonHovered, setIsButtonHovered] = useState(false)

    // Refs
    const videoRef = useRef(null)
    const containerRef = useRef(null)
    const hideTimeout = useRef(null)

    // Video source handling
    const isYouTubeLink =
        props.videoLink?.includes("youtube.com") ||
        props.videoLink?.includes("youtu.be")
    const isVimeoLink = props.videoLink?.includes("vimeo.com")
    const isEmbedCode =
        props.embedCode?.includes("<iframe") ||
        props.embedCode?.includes("<video")

    // Video control functions
    const togglePlay = () => {
        if (!videoRef.current) return

        if (isPlaying) {
            videoRef.current.pause()
        } else {
            videoRef.current
                .play()
                .then(() => {
                    setIsPlaying(true)
                    setShowBigButton(false)
                })
                .catch((error) => {
                    console.error("Play failed:", error)
                    setShowBigButton(true)
                })
        }
    }

    // Event handlers
    const handleButtonClick = (e) => {
        e.stopPropagation()
        togglePlay()
        setIsButtonHovered(true)
        setTimeout(() => setIsButtonHovered(false), 200)
    }

    const handleVideoClick = () => {
        if (props.showControls) return // Let button handle it
        togglePlay()
    }

    // Video event listeners
    useEffect(() => {
        const video = videoRef.current
        if (!video || isYouTubeLink || isVimeoLink || isEmbedCode) return

        const handlePlay = () => {
            setIsPlaying(true)
            setShowBigButton(false)
        }
        const handlePause = () => {
            setIsPlaying(false)
            setShowBigButton(true)
        }

        video.addEventListener("play", handlePlay)
        video.addEventListener("pause", handlePause)

        return () => {
            video.removeEventListener("play", handlePlay)
            video.removeEventListener("pause", handlePause)
        }
    }, [isYouTubeLink, isVimeoLink, isEmbedCode])

    // Render the center button
    const renderCenterButton = () => (
        <motion.div
            key="center-button"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
                opacity: showBigButton ? 1 : 0,
                scale: showBigButton ? 1 : 0.8,
            }}
            transition={{ duration: 0.2 }}
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                pointerEvents: "auto",
                zIndex: 10,
            }}
            onClick={handleButtonClick}
            onHoverStart={() => setIsButtonHovered(true)}
            onHoverEnd={() => setIsButtonHovered(false)}
        >
            <motion.div
                style={{
                    width: props.bigButtonSize,
                    height: props.bigButtonSize,
                    background: props.bigButtonBackground,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 8px 25px rgba(0,0,0,0.4)",
                }}
                animate={{
                    scale: isButtonHovered ? 1.1 : 1,
                    background: isButtonHovered
                        ? "rgba(0, 0, 0, 0.8)"
                        : props.bigButtonBackground,
                }}
                transition={{ duration: 0.2 }}
            >
                <motion.svg
                    style={{
                        width: props.bigButtonIconSize,
                        height: props.bigButtonIconSize,
                        fill: props.bigButtonIconColor,
                    }}
                    viewBox="0 0 24 24"
                >
                    {isPlaying ? (
                        <>
                            <rect x="6" y="5" width="4" height="14" />
                            <rect x="14" y="5" width="4" height="14" />
                        </>
                    ) : (
                        <path d="M8 5v14l11-7z" />
                    )}
                </motion.svg>
            </motion.div>
        </motion.div>
    )

    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                overflow: "hidden",
                backgroundColor: "#000",
                borderRadius: props.cornerRadius,
                cursor: "pointer",
            }}
            onClick={handleVideoClick}
            onMouseEnter={() => {
                setShowControls(true)
                setIsHovered(true)
                setShowBigButton(true)
                clearTimeout(hideTimeout.current)
            }}
            onMouseLeave={() => {
                hideTimeout.current = setTimeout(() => {
                    setShowControls(false)
                    setIsHovered(false)
                    if (isPlaying) setShowBigButton(false)
                }, 2000)
            }}
        >
            {/* Video element */}
            {!isYouTubeLink && !isVimeoLink && !isEmbedCode ? (
                <video
                    ref={videoRef}
                    src={props.videoLink || props.videoFile}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: props.videoFit,
                    }}
                    loop={props.loop}
                    muted={props.muted}
                    playsInline
                />
            ) : isEmbedCode ? (
                <div dangerouslySetInnerHTML={{ __html: props.embedCode }} />
            ) : (
                <iframe
                    src={
                        isYouTubeLink
                            ? `https://www.youtube.com/embed/${props.videoLink.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)?.[1]}`
                            : `https://player.vimeo.com/video/${props.videoLink.match(/vimeo.com\/(?:video\/)?(\d+)/)?.[1]}`
                    }
                    style={{ width: "100%", height: "100%", border: "none" }}
                    allow="autoplay; fullscreen"
                />
            )}

            {/* Center play/pause button */}
            {props.showControls && (
                <AnimatePresence>
                    {(showBigButton || isHovered) && renderCenterButton()}
                </AnimatePresence>
            )}

            {/* Bottom controls bar */}
            {props.showControls && props.type === "full" && (
                <motion.div
                    initial={{ y: 100 }}
                    animate={{ y: showControls ? 0 : 100 }}
                    transition={{ duration: 0.3 }}
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: "rgba(0,0,0,0.7)",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                >
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={(currentTime / duration) * 100 || 0}
                        onChange={(e) => {
                            const time = (e.target.value / 100) * duration
                            setCurrentTime(time)
                            if (videoRef.current)
                                videoRef.current.currentTime = time
                        }}
                        style={{
                            width: "100%",
                            height: "4px",
                            accentColor: props.brandColor,
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: "15px",
                                alignItems: "center",
                            }}
                        >
                            <button
                                onClick={togglePlay}
                                style={{ background: "none", border: "none" }}
                            >
                                {isPlaying ? (
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                    >
                                        <rect
                                            x="6"
                                            y="5"
                                            width="4"
                                            height="14"
                                        />
                                        <rect
                                            x="14"
                                            y="5"
                                            width="4"
                                            height="14"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                    >
                                        <path d="M8 5v14l11-7z" />
                                    </svg>
                                )}
                            </button>
                            <span style={{ color: "white" }}>
                                {Math.floor(currentTime / 60)}:
                                {Math.floor(currentTime % 60)
                                    .toString()
                                    .padStart(2, "0")}{" "}
                                /{Math.floor(duration / 60)}:
                                {Math.floor(duration % 60)
                                    .toString()
                                    .padStart(2, "0")}
                            </span>
                        </div>
                        <button
                            onClick={toggleFullscreen}
                            style={{ background: "none", border: "none" }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="white"
                            >
                                <path
                                    d={
                                        isFullscreen
                                            ? "M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
                                            : "M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
                                    }
                                />
                            </svg>
                        </button>
                    </div>
                </motion.div>
            )}
        </div>
    )
}

VideoPlayer.defaultProps = {
    videoFile: "",
    videoLink: "",
    embedCode: "",
    autoPlay: false,
    loop: false,
    muted: false,
    showControls: true,
    type: "full",
    bigButtonSize: 80,
    bigButtonIconSize: 32,
    bigButtonBackground: "rgba(0, 0, 0, 0.6)",
    bigButtonIconColor: "#ffffff",
    brandColor: "#0066ff",
    videoFit: "cover",
    cornerRadius: 8,
}

addPropertyControls(VideoPlayer, {
    videoFile: {
        type: ControlType.File,
        title: "Video File",
        allowedFileTypes: ["mp4", "webm", "ogg"],
    },
    videoLink: {
        type: ControlType.String,
        title: "Video URL",
        placeholder: "YouTube/Vimeo URL or direct video link",
    },
    embedCode: {
        type: ControlType.String,
        title: "Embed Code",
        placeholder: "Paste iframe embed code",
        displayTextArea: true,
    },
    autoPlay: {
        type: ControlType.Boolean,
        title: "Auto Play",
        defaultValue: false,
    },
    showControls: {
        type: ControlType.Boolean,
        title: "Show Controls",
        defaultValue: true,
    },
    type: {
        type: ControlType.Enum,
        title: "Controls Type",
        options: ["full", "simple"],
        optionTitles: ["Full Controls", "Simple Button"],
        defaultValue: "full",
    },
    bigButtonSize: {
        type: ControlType.Number,
        title: "Button Size",
        min: 40,
        max: 120,
        defaultValue: 80,
    },
    bigButtonIconSize: {
        type: ControlType.Number,
        title: "Icon Size",
        min: 16,
        max: 48,
        defaultValue: 32,
    },
})
